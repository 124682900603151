import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`For official guidance, always refer to the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/"
      }}>{`WCAG 2.1 W3C documentation`}</a>{`. You can find failure examples for each WCAG standard, and techniques for fixing them in `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/quickref/?versions=2.1&showtechniques=148&currentsidebar=%23col_customize#visual-presentation"
      }}>{`How to Meet WCAG (Quick Reference)`}</a>{`.`}</p>
    <h2>{`It starts with you`}</h2>
    <p>{`Accessible experiences start with designers. Tacking on accessibility after a design is implemented costs businesses time and money, and it muddies the original design, leading to a poor user experience for people with and without disabilities.`}</p>
    <p>{`When we consider accessibility from the beginning, we design for everyone from the start.`}</p>
    <p>{`Consider how different people will experience your design. For an informative list on real-life disability situations, check `}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20230307004829/https://the-pastry-box-project.net/anne-gibson/2014-july-31"
      }}>{`An Alphabet of Accessibility Issues`}</a>{`, published in The Pastry Box Project.`}</p>
    <h2>{`Feature checklist`}</h2>
    <p>{`If you're designing a feature or experience in GitHub, use the following checklist to assess your design for accessibility:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Have you annotated the headings on your feature?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Have you defined the focus/tabbing order on your feature?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Have you added screen reader labels for elements that are not focusable? (Dynamic content changes, images and their alt text)`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`If this is a new page outside the GitHub monolith, are the landmarks annotated?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Does the text meet color contrast?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Do graphic objects meet color contrast?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`With color removed, can you still understand the design?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Can all revealable information (hover card, tooltips, accordions) be accessed with a keyboard or a mouse?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`If the user increases the text size to 200%, is the page still usable?`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`When the screen reduces in size (down to 320px wide), is everything usable without scrolling horizontally? (There are a few exceptions to this rule: check `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/reflow.html"
        }}>{`1.4.10 - Reflow`}</a>{` for details)`}</li>
    </ul>
    <h2>{`Basic visual considerations`}</h2>
    <p>{`WCAG 2.1 includes more nuanced considerations than what is listed here, but these basic considerations will give you a strong foothold into accessible design.`}</p>
    <h3>{`Color`}</h3>
    <h4>{`Contrast`}</h4>
    <p>{`Text and user interface components on a page must meet a specific minimum contrast ratio to pass WCAG 2.1 AA standards.`}</p>
    <p><strong parentName="p">{`Why?`}</strong>{` People with low vision may have trouble reading or understanding important information. Depending on the contrast ratio, users may not see the item at all. Besides being perceived as disabled items, this makes users struggle with actions like making sure they select the correct option.`}</p>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/40274682/102424769-bc476b80-3fc0-11eb-9831-c229ada406c0.png" alt="" />
    <Caption mdxType="Caption">Buttons with sufficient text contrast</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/40274682/102424747-afc31300-3fc0-11eb-9e98-ecd806f6e266.png" alt="" />
    <Caption mdxType="Caption">Buttons with insufficient text contrast</Caption>
  </Dont>
    </DoDontContainer>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Normal text (14pt)`}</strong>{`: 4.5:1 minimum contrast`}</li>
      <li parentName="ul"><strong parentName="li">{`Large text (14pt bold + , 18pt +)`}</strong>{`: 3:1 minimum contrast`}</li>
      <li parentName="ul"><strong parentName="li">{`Graphical objects and user interface components`}</strong>{`: 3:1 minimum contrast`}</li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` Disabled controls do not need to hit any specific contrast levels.`}</p>
    <p>{`Official WCAG guidelines:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
        }}>{`1.4.3 - Contrast (Minimum)(Level AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html"
        }}>{`1.4.11 - Non-text Contrast (Level AA)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-without-color.html"
        }}>{`1.4.1 - Use of color (Level AA)`}</a></li>
    </ul>
    <h4>{`Use of color`}</h4>
    <p>{`The main point in the guideline `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-without-color.html"
      }}>{`1.4.1 Use of Color`}</a>{` is to make sure that color isn't the sole identifier. If you have an important transition that is only a change in color, make sure that color change has a 3:1 contrast ratio.`}</p>
    <p>{`A great way to test for this is to navigate that experience in greyscale mode.`}</p>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/40274682/102531800-cb2d2d00-4057-11eb-8372-ef42011d3e0a.png" alt="" />
    <Caption mdxType="Caption">Use color and a different shape to indicate issue status</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/40274682/102531791-c4061f00-4057-11eb-955f-82e511b663d5.png" alt="" />
    <Caption mdxType="Caption">Don't use only color to indicate issue status</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Typography`}</h3>
    <h4>{`Links`}</h4>
    <p>{`Assistive technology users will frequently browse a list of links for easy navigation. For this reason, make sure that the link text is meaningful and unique. There should be as few duplicated references as possible to prevent confusion.`}</p>
    <p>{`For more detail on accessible links, read the W3C's `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
      }}>{`Understanding 2.4.4 - Link Purpose`}</a>{`.`}</p>
    <DoDontContainer stacked mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Code className="language-html" mdxType="Code">
      {`<p>
  Find out more about <a href="#url">GitHub Enterprise pricing</a>.<br />
</p>`}
    </Code>
    <Caption mdxType="Caption">Make links meaningful and easy to understand</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Code className="language-html" mdxType="Code">
      {`<p>
  To find out more about GitHub Enterprise pricing,
  <a href="#url">click here</a>.
</p>`}
    </Code>
    <Caption mdxType="Caption">Don't create links that give no indication to the user</Caption>
  </Dont>
    </DoDontContainer>
    <h4>{`Forms`}</h4>
    <ul>
      <li parentName="ul">{`All form inputs should have a visible label`}</li>
      <li parentName="ul">{`Don't use placeholder text for titles, hints, or other important information.`}</li>
    </ul>
    <p><strong parentName="p">{`Why?`}</strong>{` Visible labels determine the purpose for inputs, and screen readers can read this information without extra code. If placeholder text contains important information and that information disappears when one starts to type, those with cognitive disabilities will have difficulty completing the form.`}</p>
    <h4>{`Content on hover or focus`}</h4>
    <p>{`Hover cards and tool tips need to be approached with care. To meet the bare minimum for accessibility, tooltips need to be:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Dismissible`}</strong>{`: Be able to dismiss tooltips without moving the pointer or keyboard focus (unless it's an input error or does not cover other content).`}</li>
      <li parentName="ul"><strong parentName="li">{`Hoverable`}</strong>{`: Pointer can move over the hover content without the content disappearing.`}</li>
      <li parentName="ul"><strong parentName="li">{`Persistent`}</strong>{`: The tooltip doesn't dismiss itself without user action.`}</li>
    </ul>
    <p>{`For more information read `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/content-on-hover-or-focus.html"
      }}>{`1.4.13 Content on Hover or Focus`}</a>{`.`}</p>
    <h4>{`Text`}</h4>
    <p>{`People using the web should be able to increase font size to 200% without losing content or functionality.`}</p>
    <p>{`If you'd like to go the extra mile and meet AAA guidelines, you can make sure that:`}</p>
    <ul>
      <li parentName="ul">{`The width of text doesn't exceed 80 characters or glyphs`}</li>
      <li parentName="ul">{`Text isn't justified (align text to the left and right)`}</li>
    </ul>
    <p>{`Read Primer's `}<a parentName="p" {...{
        "href": "/foundations/typography"
      }}>{`typography`}</a>{` guidelines for more detail about using type on GitHub.`}</p>
    <h2>{`Keyboard accessibility`}</h2>
    <p>{`All interactive elements need to be accessed and activated by a keyboard alone (`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG21/#keyboard-accessible"
      }}>{`2.1 Keyboard Accessible`}</a>{`).`}</p>
    <ul>
      <li parentName="ul">{`Tabbing should be used to access standalone controls (buttons, links, widgets), and arrow keys should be used to navigate within a composite control (group of standalone controls, list of checkboxes).`}</li>
      <li parentName="ul">{`Some assistive technologies have keyboard combinations that override those of a web page, so don't rely on keyboard shortcuts as the only alternative to mouse actions.`}</li>
      <li parentName="ul">{`Focus must be visible for all interactive elements. `}<a parentName="li" {...{
          "href": "/guides/accessibility/focus-management"
        }}>{`Read more about focus management`}</a></li>
    </ul>
    <h2>{`Resources`}</h2>
    <h3>{`Understanding WCAG 2.1`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://knowbility.org/search/?q=WCAG+2.1"
        }}>{`Knowbility.org`}</a>{`: Search for 'WCAG 2.1' on Knowbility to get a more clear and succinct interpretation of WCAG 2.1's official guidance.`}</li>
      <li parentName="ul">{`The UK's Home Office designed `}<a parentName="li" {...{
          "href": "https://github.com/UKHomeOffice/posters/blob/master/accessibility/dos-donts/posters_en-UK/accessibility-posters-set.pdf"
        }}>{`easy-to-read posters`}</a>{` on designing for accessibility, broken out into principles for various conditions. You can read about `}<a parentName="li" {...{
          "href": "https://accessibility.blog.gov.uk/2016/09/02/dos-and-donts-on-designing-for-accessibility/"
        }}>{`designing the posters`}</a>{` on the team's accessibility blog.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.a11yproject.com/resources/"
        }}>{`a11yproject`}</a>{` includes a wealth of resources on web accessibility.`}</li>
    </ul>
    <h3>{`Pattern libraries`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/"
        }}>{`WAI-ARIA Authoring Practices 1.2`}</a>{` is an in-depth list of UI patterns and how to implement them, from W3C.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://inclusive-components.design/"
        }}>{`Inclusive components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.a11ymatters.com/patterns"
        }}>{`a11ymatters patterns`}</a>{` includes useful information for developers.`}</li>
    </ul>
    <h3>{`Screen readers`}</h3>
    <p>{`Top three screen readers for computers (`}<a parentName="p" {...{
        "href": "https://webaim.org/projects/screenreadersurvey8/"
      }}>{`data from WebAIM`}</a>{`, August 2019):`}</p>
    <ul>
      <li parentName="ul">{`NVDA (Windows)`}</li>
      <li parentName="ul">{`JAWS (Windows)`}</li>
      <li parentName="ul">{`VoiceOver (Mac)`}</li>
    </ul>
    <p>{`Top three screen readers for mobile devices (`}<a parentName="p" {...{
        "href": "https://webaim.org/projects/screenreadersurvey8/"
      }}>{`data from WebAIM`}</a>{`, August 2019):`}</p>
    <ul>
      <li parentName="ul">{`VoiceOver (iOS)`}</li>
      <li parentName="ul">{`TalkBack for Android (Android)`}</li>
      <li parentName="ul">{`Voice Assistant (Android)`}</li>
    </ul>
    <p>{`Keep in mind that just like there are different browser implementations, there are also different assistive technologies and their behaviors can vary.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      